<template>
  <tr>
    <td style="width: 50px">
      <label class="form-checkbox">
        <input type="checkbox"
               v-model="item.selected"
               @change="$emit('onChange')">
        <i class="form-icon"></i>
      </label>
    </td>
    <td>{{ item.name }}</td>
  </tr>
</template>

<script>

export default {
  props: {
    entityId: {
      type: String,
    },
    changed: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  created() {
    this.item = this.data;
  },
  data() {
    return {
      item: {
        id: '',
        name: '',
        selected: false,
      },
      saving: false,
    };
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";

.collaborator-insurance-item {
}
</style>
