<template>
  <st-side-page class="collaborator-page">
    <portal to="page-name">Cadastro de colaborador</portal>
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <template slot="side">
        <div class="collaborator-image" :style="bgImage"
             :class="{'show-options': showImageOptions}">
          <div class="collaborator-image-options">
            <template v-if="bgImage">
              <button class="btn btn-icon btn-error tooltip" data-tooltip="Remover foto"
                      @click="removeImage()">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar foto"
                      @click="image.showOptions = true">
                <fa-icon :icon="['fal', 'upload']"/>
                <input type="file" name="image" accept="image/*"
                       @change="loadImage">
              </button>
              <button class="btn btn-icon btn-gray tooltip" data-tooltip="Tirar foto"
                      @click="openWebCam" disabled>
                <fa-icon :icon="['fal', 'camera']"/>
              </button>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-12 form-group mt-2">
            <button class="btn btn-block btn-primary" @click="saveCollaborator"
                    :disabled="saving" :class="{loading: saving}">
              Salvar
            </button>
          </div>
          <div class="column col-12 form-group">
            <button class="btn btn-block" @click="$router.back()">
              Voltar
            </button>
          </div>
        </div>
        <ul class="menu">
          <li class="menu-item" v-for="(menu, i) in menus" :key="i">
            <router-link :to="menu.path">{{ menu.label }}</router-link>
          </li>
        </ul>
      </template>
      <div class="card">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.collaborator.active">
            <i class="form-icon"/> Registro ativo
          </label>
        </div>
        <div class="card-title">
          Cadastro de colaborador
          <span v-if="form.name"> - </span>
          <small class="text-primary"> {{ form.name }}</small>
        </div>
      </div>
      <div class="card-body">
        <st-tabs ref="collaborator-tabs">
          <!-- TAB: MAIN -->
          <st-tab id="collaborator" name="Dados principais">
            <div class="columns">
              <div class="column col-3 col-md-4 col-sm-12 form-group"
                   :class="{'has-error': $v.form.identity.value.$error}">
                <label for="cpf" class="form-label">CPF</label>
                <input
                  type="text"
                  id="cpf"
                  v-model="form.identity.value"
                  @blur="$v.form.identity.value.$touch()"
                  @change="loadEntityCollaborator"
                  :disabled="isIdentitySaved"
                  class="form-input"
                  autocomplete="nope"
                  placeholder="000.000.000-00"
                  v-mask-cpf
                >
                <template v-if="$v.form.identity.value.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.identity.value.required">Campo obrigatório</div>
                  <div class="form-input-hint"
                       v-if="!$v.form.identity.value.cpf">CPF inválido</div>
                </template>
              </div>
              <div class="column col-4 col-md-4 col-sm-12 form-group"
                   :class="{'has-error': $v.form.name.$error}">
                <label for="company-name" class="form-label">Nome</label>
                <input type="text" id="company-name" name="company-name"
                       v-model="form.name" class="form-input" :disabled="!!form.id"
                       @blur="$v.form.name.$touch()" @change="loadEntityByNameAndDob"
                       placeholder="Nome" autocomplete="nope">
                <template v-if="$v.form.name.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.name.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-3 col-sm-12 form-group"
                   :class="{'has-error': $v.form.birthDate.$error}">
                <label for="person-birth-date" class="form-label">Nascimento</label>
                <input type="text" id="person-birth-date" name="person-birth-date"
                       v-model="form.birthDate" class="form-input"
                       @change="loadEntityByNameAndDob" :disabled="!!form.id"
                       @blur="$v.form.birthDate.$touch()" key="person-birth-date"
                       placeholder="00/00/0000" v-mask-date.br>
                <template v-if="$v.form.birthDate.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.birthDate.required">Campo obrigatório</div>
                  <div class="form-input-hint"
                       v-else-if="!$v.form.birthDate.date">Data inválida</div>
                </template>
              </div>
              <div class="column col-2 col-sm-12 form-group"
                   :class="{'has-error': $v.form.gender.$error}">
                <label class="form-label">Sexo</label>
                <select id="person-gender" name="person-gender"
                        :disabled="!!form.id && !!form.gender"
                        v-model="form.gender" key="person-gender"
                        class="form-select" @change="$v.form.gender.$touch()">
                  <option value="">[Selecione]</option>
                  <option value="female">Feminino</option>
                  <option value="male">Masculino</option>
                </select>
                <template v-if="$v.form.gender.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.gender.required">Campo obrigatório</div>
                </template>
              </div>
            </div>
            <div class="columns">
              <div class="column col-3 col-md-4 col-sm-12 form-group"
                   :class="{'has-error': $v.form.phone.$error}">
                <label for="phone" class="form-label">Telefone</label>
                <input type="text" id="phone" name="phone" v-model="form.phone"
                       class="form-input" @blur="$v.form.phone.$touch()" maxlength="14"
                       placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
                <template v-if="$v.form.phone.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.phone.phone">Telefone inválido</div>
                </template>
              </div>
              <div class="column col-3 col-md-4 col-sm-12 form-group"
                   :class="{'has-error': $v.form.cellphone.$error}">
                <label for="cellphone" class="form-label">Celular</label>
                <input type="text" id="cellphone" name="cellphone"
                       v-model="form.cellphone" class="form-input" autocomplete="nope"
                       @blur="$v.form.cellphone.$touch()"
                       placeholder="(00) 0 0000-0000" v-mask-phone.br>
                <template v-if="$v.form.cellphone.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.cellphone.phone">Telefone inválido</div>
                </template>
              </div>
              <div
                class="column col-3 col-md-4 col-sm-12 form-group"
                :class="{'has-error': $v.form.email.$error}"
              >
                <label for="email" class="form-label">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  v-model="form.email"
                  class="form-input"
                  @blur="$v.form.email.$touch()"
                  placeholder="nome@email.com"
                  autocomplete="nope"
                  :disabled="!canEdit"
                >
                <template v-if="$v.form.email.$error">
                  <div
                    class="form-input-hint"
                    v-if="!$v.form.email.email"
                  >Email inválido</div>
                </template>
              </div>
              <div class="column col-3 col-sm-12 form-group">
                <label class="form-label">
                  Gerenciar agendas médicas
                </label>
                <select id="can-view-schedule" :disabled="!form.id"
                        class="form-select" v-model="canViewSchedule"
                        @change="saveScheduleSettings">
                  <option value="false">Não</option>
                  <option value="true">Sim</option>
                </select>
              </div>
            </div>
            <div class="text-gray">Dados do endereço</div>
            <div class="divider"></div>
            <div class="columns">
              <div class="column col-3 col-md-6 col-sm-12 form-group"
                   :class="{'has-error': $v.address.postalCode.$error}">
                <label for="address-cep" class="form-label">CEP</label>
                <div class="input-group">
                  <input type="text" id="address-cep" name="address-cep"
                         class="form-input" v-model="address.postalCode"
                         @blur="$v.address.postalCode.$touch()" placeholder="00.000-000"
                         @change="loadAddress" v-mask-cep autocomplete="nope">
                  <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                    :disabled="loadingAddress" :class="{loading: loadingAddress}">
                    <fa-icon :icon="['fal', 'search']"/>
                  </button>
                </div>
                <template v-if="$v.address.postalCode.$error">
                  <div class="form-input-hint"
                       v-if="!$v.address.postalCode.required">Campo obrigatório</div>
                  <div class="form-input-hint"
                       v-if="!$v.address.postalCode.cep">CEP inválido</div>
                </template>
              </div>
              <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
              <div class="column col-9 col-md-12 col-sm-12 form-group">
                <label for="address-addressLine1" class="form-label">Logradouro</label>
                <input type="text" id="address-addressLine1" name="address-addressLine1"
                       class="form-input" v-model="address.addressLine1"
                       placeholder="Avenida Brasil, 1000" autocomplete="nope">
              </div>
              <div class="column col-3 col-md-6 col-sm-12 form-group">
                <label for="address-addressLine2" class="form-label">Complemento</label>
                <input type="text" id="address-addressLine2" name="address-addressLine2"
                       class="form-input" v-model="address.addressLine2"
                       placeholder="casa, sobrado, loja..." autocomplete="nope">
              </div>
              <div class="column col-5 col-md-6 col-sm-12 form-group">
                <label for="address-neighborhood" class="form-label">Bairro</label>
                <input type="text" id="address-neighborhood" name="address-neighborhood"
                       placeholder="Bairro" v-model="address.neighborhood"
                       class="form-input" autocomplete="nope">
              </div>
              <div class="column col-4 col-md-6 col-sm-12 form-group">
                <label for="address-city" class="form-label">Cidade</label>
                <input type="text" id="address-city" name="address-city"
                       class="form-input" v-model="address.city"
                       placeholder="Cidade" autocomplete="nope">
              </div>
              <div class="column col-3 col-md-6 col-sm-12 form-group">
                <label class="form-label">Estado</label>
                <select id="address-state" name="address-state"
                       class="form-select" v-model="address.state">
                  <option value="">[Selecione]</option>
                  <option v-for="(text, value) in states"
                          :value="value" :key="value">{{ value }}</option>
                </select>
              </div>
            </div>
            <div class="text-gray">Notas</div>
            <div class="columns">
              <div class="column col-12 form-group">
                <label class="form-label">Observações</label>
                <textarea id="notes" name="notes" class="form-input" rows="4"
                          v-model="form.notes"/>
              </div>
              <div class="column col-12 form-group">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="form.collaborator.useCallCenter">
                  <i class="form-icon"/>Utiliza central de agendamentos (Call center)
                </label>
              </div>
            </div>

            <div class="text-gray mt-2">Dados do usuário</div>
            <div class="divider"></div>
            <div class="columns">
              <div class="column col-6 mb-2">
                <label
                  for="user-permission-group"
                  class="form-label"
                >Grupo de permissões</label>
                <select
                  id="user-permission-group"
                  class="form-select"
                  v-model="form.collaborator.permissionGroup"
                  :disabled="!canEdit && !!form.collaborator.permissionGroup"
                >
                  <option value="">[Selecione]</option>
                  <option
                    v-for="group in permissionGroups"
                    :value="group.id"
                    :key="group.id"
                  >
                    {{ group.name }}
                    <span v-if="group.default"> (Padrão)</span>
                  </option>
                </select>
              </div>

              <!-- USER PASSWORD -->
              <div class="column col-12 form-group" v-if="!userHasPassword">
                <div class="columns">
                  <div
                    class="column col-3 col-sm-12 form-group"
                    :class="{'has-error': $v.form.collaborator.password.$error}"
                  >
                    <label for="password" class="form-label">Senha</label>
                    <input
                      type="password"
                      class="form-input"
                      id="password"
                      v-model="form.collaborator.password"
                      @change="$v.form.collaborator.password.$touch()"
                      placeholder="senha"
                    >
                    <template v-if="$v.form.collaborator.password.$error">
                      <p
                        class="form-input-hint"
                        v-if="!$v.form.collaborator.password.required"
                      >Campo obrigatório</p>
                      <p
                        class="form-input-hint"
                        v-if="!$v.form.collaborator.password.minLength"
                      >A senha deve conter no mínimo 6 caracteres</p>
                    </template>
                  </div>
                  <div
                    class="column col-3 col-sm-12 form-group"
                    :class="{'has-error': $v.form.collaborator.passwordConfirmation.$error}"
                  >
                    <label for="password-confirmation" class="form-label">Confirme a senha</label>
                    <input
                      type="password"
                      class="form-input"
                      id="password-confirmation"
                      v-model="form.collaborator.passwordConfirmation"
                      @change="$v.form.collaborator.passwordConfirmation.$touch()"
                      placeholder="senha"
                    >
                    <template v-if="$v.form.collaborator.passwordConfirmation.$error">
                      <p
                        class="form-input-hint"
                        v-if="!$v.form.collaborator.passwordConfirmation.required"
                      >Campo obrigatório</p>
                      <p
                        class="form-input-hint"
                        v-else-if="!$v.form.collaborator.passwordConfirmation.sameAsPass"
                      >Senhas não conferem</p>
                    </template>
                  </div>
                </div>
              </div>
              <!-- USER PASSWORD - END -->
            </div>
          </st-tab>

          <!-- TAB: INSURANCES -->
          <st-tab id="insurances" name="Convênios" v-if="form.collaborator.useCallCenter">
            <insurances
              v-if="form.id"
              :entity-id="form.id"
              :insurances="form.collaborator.insurances"
            />
            <div class="empty mt-2" v-else>
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h5">Convênios</p>
              <p class="empty-subtitle">
                Você precisa salvar para ter acesso a lista de convênios.
              </p>
            </div>
          </st-tab>
        </st-tabs>
      </div>
    </div>
    </template>
  </st-side-page>
</template>

<script>
import { mapGetters } from 'vuex';
import entityMixin from 'src/mixins/entity';
import { cep, cpf } from 'src/data/validators';
import { required, minLength, sameAs } from 'vuelidate/src/validators';
import Insurances from './Insurances.vue';

export default {
  mixins: [entityMixin],
  components: {
    Insurances,
  },
  data() {
    return {
      type: 'collaborator',
      path: '/collaborators',
      includesUserData: true,
      insurances: [],
      permissionGroups: [],
      menus: [],
      form: {},
      image: this.imageFormBlank(),
      canViewSchedule: false,
    };
  },
  created() {
    this.form = this.blankForm();
  },
  validations() {
    return this.entityValidationRules((rules) => {
      const localRules = rules;
      localRules.address = {
        postalCode: { cep },
      };
      localRules.form.identity = {
        value: { required, cpf },
      };
      localRules.nameAndDob = ['form.name', 'form.birthDate'];

      localRules.form.collaborator = {
        password: {},
        passwordConfirmation: {},
      };

      if (!this.userHasPassword) {
        localRules.form.collaborator.password = {
          required,
          minLength: minLength(6),
        };

        localRules.form.collaborator.passwordConfirmation = {
          required,
          sameAsPass: sameAs('password'),
        };
      }

      return localRules;
    });
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.form.type = 'person';

    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      await this.load(this.form.id, this.path)
        .then(() => {
          this.loadScheduleSettings();
        });
    }

    await this.loadPermissionGroups();
  },
  computed: {
    ...mapGetters(['isUserRestricted']),
    bgImage() {
      let backgroundImage = null;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.form.imageUrl) {
        backgroundImage = `url('${this.form.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
    canEdit() {
      return !this.isUserRestricted(this.form.id);
    },
    userHasPassword() {
      return this.form.id && this.form.user.usernames.length > 0;
    },
  },
  methods: {
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    removeImage() {
      this.image = this.imageFormBlank();
      this.form.imageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/images`)
        .catch(() => {});
    },
    openWebCam() {
      this.image.showWebCam = true;
      this.image.showOptions = true;
      this.$nextTick(() => this.$refs.btnTakeSnapshot.focus());
    },
    clearPassword() {
      this.$v.form.collaborator.$reset();
      this.form.collaborator.password = '';
      this.form.collaborator.passwordConfirmation = '';
    },
    loadEntityCollaborator() {
      this.loadEntityByIdentity(this.path);
    },
    loadEntityByNameAndDob() {
      if (this.$v.nameAndDob.$invalid) return;
      const params = {
        name: this.form.name,
        birthDate: this.dateToApi(this.form.birthDate),
      };
      this.loadEntity({ params }, this.path);
    },
    saveCollaborator() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.form.type = 'person';
      this.address.type = 'residential';

      this.save(this.path)
        .then((data) => {
          if (this.isNew) {
            this.$router.replace(`${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          if (this.image.file) {
            const formData = new FormData();
            formData.append('file', this.image.file);
            this.$http.post(`/entities/${data.id}/images`, formData)
              .then(() => {
                this.image.file = null;
              })
              .catch(() => {});
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    loadScheduleSettings() {
      if (!this.form.id) {
        return false;
      }

      return this.$http.get(`/schedule-settings/${this.form.id}`)
        .then(({ data }) => {
          this.canViewSchedule = data;
        })
        .catch(() => {});
    },
    saveScheduleSettings() {
      if (!this.form.id) {
        return;
      }

      const data = {
        entityId: this.form.id,
        canViewSchedule: this.canViewSchedule === 'true',
      };

      this.$http.post('/schedule-settings', data)
        .catch(() => {});
    },
    loadPermissionGroups() {
      const params = {
        limit: 0,
      };
      return this.$http.get('/groups', { params })
        .then(({ data }) => {
          this.permissionGroups = data.items;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .collaborator-page {
    &.side-page .side-page-side {
      flex: 0 0 10.1rem;
      dd {
        margin-top: 0;
      }
    }
    .collaborator-image {
      margin-top: $layout-spacing;
      background: $gray-color-light center no-repeat;
      background-size: cover;
      border-radius: $border-radius;
      height: 9rem;
      width: 9rem;
      &:not(.show-options):hover {
        .collaborator-image-options {
          opacity: 1;
          background-color: rgba($gray-color-light, .97);
        }
      }
      &.show-options {
        .collaborator-image-options {
          opacity: 1;
        }
      }
    }
    .collaborator-image-options {
      align-items: center;
      background-color: $gray-color-light;
      border-radius: $border-radius;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: all .3s ease;
      .btn {
        margin: calc($layout-spacing / 2);
        position: relative;
        input {
          bottom: 0;
          display: block;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: $zindex-0;
        }
      }
    }
    .panel {
      background-color: #fff;
    }
    .table-title {
      background-color: $gray-color-light !important
    }
  }
</style>
