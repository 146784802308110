<template>
  <div class="collaborator-insurances">
    <div class="loading loading-lg mt-2" v-if="loading" />
    <template v-else>
      <div class="empty mt-2" v-if="insuranceItems.length ===0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <div class="empty-title h6">Convênios</div>
        <div class="empty-subtitle">
          <slot>Nenhum convênio cadastrado.</slot>
        </div>
      </div>
      <template v-else>
        <div class="insurance-action">
          <div class="columns">
            <div class="column">
              <button class="btn btn-icon btn-icon-left btn-warning mb-1"
                      :class="{loading: saving}"
                      :disabled="!changed || saving" @click="bulk">
                <fa-icon :icon="['fal', 'save']" />Salvar alterações
              </button>
            </div>
            <div class="column col-4 text-right">
              <div class="input-group" v-if="!changed">
                <input type="text" class="form-input" v-model="search"
                       placeholder="Filtrar convênio...">
                <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                        tabindex="-1" v-if="!search.trim()">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
                <button class="btn btn-gray btn-action input-group-btn btn-icon"
                        tabindex="-1" v-else @click="search = ''">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>
              <label class="form-checkbox">
                <input type="checkbox"
                       @change="selectAllInsurances"
                       :checked="getInsuranceSelected"
                       :indeterminate.prop="getInsuranceSelected === null">
                <i class="form-icon"></i>
              </label>
            </th>
            <th>Convênio</th>
          </tr>
          </thead>
          <tbody>
          <insurance-item
            v-for="item in filteredInsurances"
            :entity-id="entityId"
            :data="item"
            :key="item.id"
            :changed="changed"
            @onChange="changed = true"
          />
          </tbody>
        </table>
      </template>
    </template>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import fuzzy from 'fuzzy';
import InsuranceItem from './InsuranceItem.vue';

export default {
  mixins: [formMixin],
  components: {
    InsuranceItem,
  },
  props: {
    entityId: {
      type: String,
    },
  },
  data() {
    return {
      changed: false,
      loading: false,
      saving: false,
      search: '',
      insuranceItems: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadInsurances();
    await this.load();
    this.loading = false;
  },
  computed: {
    getInsuranceSelected() {
      if (!this.insuranceItems || this.insuranceItems.length === 0) {
        return false;
      }

      const selected = this.insuranceItems
        .filter(item => item.selected);

      if (selected.length === 0) {
        return false;
      }

      if (selected.length === this.insuranceItems.length) {
        return true;
      }

      return null;
    },
    filteredInsurances() {
      let { insuranceItems } = this;
      if (this.search.trim()) {
        const results = fuzzy.filter(this.search, insuranceItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        insuranceItems = results.map(({ original }) => original);
      }

      return insuranceItems;
    },
  },
  methods: {
    load() {
      return this.$http.get(`/collaborators/${this.entityId}/insurances`)
        .then(({ data }) => {
          data.items.forEach((item) => {
            const found = this.insuranceItems
              .find(({ id }) => id === item.plan.id);
            if (found) {
              found.selected = true;
            }
          });
          this.insuranceItems.sort((a, b) => {
            if (a.selected > b.selected) return -1;
            if (a.selected < b.selected) return 1;
            return 0;
          });
        })
        .catch(() => {});
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insuranceItems = data.items.map(item => ({
            id: item.plan.id,
            name: item.customName,
            insuranceId: item.id,
            selected: false,
          }));
        })
        .catch(() => {});
    },
    selectAllInsurances(e) {
      this.changed = true;
      this.insuranceItems.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    bulk() {
      if (!this.changed || this.saving) {
        return null;
      }

      const data = this.insuranceItems
        .map(item => ({
          id: item.id,
          name: item.name,
          selected: item.selected,
        }));

      this.saving = true;
      return this.$http.post(`/collaborators/${this.entityId}/insurances-bulk`, data)
        .then(() => {
          this.changed = false;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.collaborator-insurances {
  .insurance-action {
    background-color: $light-color;
    border-bottom: $border-width solid $border-color;
    padding: $layout-spacing 0;
    position: sticky;
    top: 0;
    z-index: $zindex-1;
  }
}
</style>
